import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import downloadjs from 'downloadjs';
import { Observable, of } from 'rxjs';

export interface TabDocument {
  url: string;
  title: string;
  mimetype?: string;
}

const iframeName = 'tabDocument';

function openTab(): Observable<Window> {
  return new Observable((observer) => {
    const _window = window.open('', '_blank');
    if (_window.document.readyState === 'complete') {
      observer.next(_window);
      observer.complete();
    } else {
      _window.onload = () => {
        observer.next(_window);
        observer.complete();
      };
    }
  });
}

function appendIframe(_window, tabDocument: TabDocument): HTMLIFrameElement {
  const _iframe: HTMLIFrameElement = _window.document.createElement('iframe');

  _iframe.src = tabDocument.url;
  _iframe.width = '100%';
  _iframe.height = '100%';
  _iframe.style.border = 'none';
  _iframe.setAttribute('id', iframeName);
  _iframe.setAttribute('name', iframeName);

  // title
  const pageTitleElement = _window.document.createElement('title');
  pageTitleElement.appendChild(_window.document.createTextNode(tabDocument.title));
  _window.document.head.appendChild(pageTitleElement);

  // iframe
  _window.document.body.appendChild(_iframe);
  _window.document.body.style.margin = 0;

  return _iframe;
}

@Injectable({
  providedIn: 'root',
})
export class ObDownloadService {

  // map document url to local documents
  private downloadedDocuments: { [key: string]: Blob } = {};

  constructor(
    private httpClient: HttpClient,
  ) { }

  getBlob(tabDocument: TabDocument): Observable<Blob> {
    const documentURL = tabDocument.url;
    if (this.downloadedDocuments[documentURL]) {
      return of(this.downloadedDocuments[documentURL]);
    }
    return this.httpClient.get(tabDocument.url, { responseType: 'blob' })
      .pipe(map((blob) => {
        this.downloadedDocuments[documentURL] = blob;
        return blob;
      }));
  }

  downloadFile(tabDocument: TabDocument): Observable<File | Blob> {
    return this.getBlob(tabDocument)
      .pipe(map((blob) => {
        try {
          return new File([blob],
            tabDocument.title,
            { type: tabDocument.mimetype || 'application/pdf' });
        } catch {
          return new Blob([blob],
            { type: tabDocument.mimetype || 'application/pdf' });
        }
      }));
  }

  // return the same TabDocument except with a local URL
  downloadDocument(tabDocument: TabDocument): Observable<TabDocument> {
    return this.downloadFile(tabDocument)
      .pipe(map((file: File | Blob) => {
        // replace remote URL with local URL
        tabDocument.url = URL.createObjectURL(file);
        return tabDocument;
      }));
  }

  // trigger browser download
  download(tabDocument: TabDocument): void {
    this.getBlob(tabDocument)
      .subscribe((blob) => {
        downloadjs(blob, tabDocument.title, { type: tabDocument.mimetype || 'application/pdf' });
      });
  }

}
