import { Component } from '@angular/core';
import { ObDownloadService } from './ob-download.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-test-zone';

  constructor(private obDownloadService: ObDownloadService) {
  }

  download(): void {
    this.obDownloadService.download({title: 'test', url: './assets/test.pdf'});
  }
}
